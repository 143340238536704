import './owl.carousel';

/**
 * Carousels
 */
(function ($) {

  // Clients
  const clientsCarousel = $('.clients-carousel');
  clientsCarousel.owlCarousel({
    autoplay: true,
    loop: true,
    margin: 15,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 4,
      }
    }
  });

  // Testimonials
  const testimonialsCarousel = $('.testimonials-carousel');
  testimonialsCarousel.owlCarousel({
    items: 1,
    autoplay: true,
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
  });

  // Events
  const eventsCarousel = $('.events-carousel');
  eventsCarousel.owlCarousel({
    items: 1,
    autoplay: true,
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
  });

  // Creates the Linked plugin.
  class Linked {
    constructor(carousel) {
      // Reference to the core.
      this._core = carousel;

      // All event handlers.
      this._handlers = {
        'dragged.owl.carousel changed.owl.carousel': $.proxy(function (e) {
          if (e.namespace && this._core.settings.linked) {
            this.update(e);
          }
        }, this),
        'linked.to.owl.carousel': $.proxy(function(e, index, speed, standard, propagate) {
          if (e.namespace && this._core.settings.linked) {
            this.toSlide(index, speed, propagate);
          }
        }, this)
      };

      // Register event handlers.
      this._core.$element.on(this._handlers);

      // Set default options
      this._core.options = $.extend({}, Linked.Defaults, this._core.options);
    }
  }
  
  // Default options.
  Linked.Defaults = {
    linked: false
  };

  // Updated linked instances.
  Linked.prototype.update = function(e) {
    this.toSlide( e.relatedTarget.relative(e.item.index) );
  };

  // Carry out the to.owl.carousel proxy function.
  Linked.prototype.toSlide = function(index, speed, propagate) {
    const id = this._core.$element.attr('id');
    const linked = this._core.settings.linked.split(',');

    if (typeof propagate == 'undefined') {
      propagate = true;
    }

    index = index || 0;

    if ( propagate ) {
      $.each(linked, function(i, el) {
        $(el).trigger('linked.to.owl.carousel', [index, 300, true, false]);
      });
    }
    else {
      this._core.$element.trigger('to.owl.carousel', [index, 300, true]);

      if ( this._core.settings.current ) {
        this._core._plugins.current.switchTo(index);
      }
    }
  };

  // Destroys the plugin.
  Linked.prototype.destroy = function() {
    let handler, property;

    for (handler in this._handlers) {
      this.$element.off(handler, this._handlers[handler]);
    }

    for (property in Object.getOwnPropertyNames(this)) {
      typeof this[property] != 'function' && (this[property] = null);
    }
  };

  // Registers the plugin.
  $.fn.owlCarousel.Constructor.Plugins.linked = Linked;

  // Milestones
  const milestonesCarousel = $('.milestones-carousel');
  milestonesCarousel.owlCarousel({
    items: 1,
    dots: false,
    center: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    linked: '.years-carousel',
  });

  // Years
  let yearsCarousel = $('.years-carousel');
  yearsCarousel.on('initialize.owl.carousel link.to.owl.carousel', function () {
    $(this.$element).find('.owl-item.current').removeClass('current');

    let current = $(this.$element).find('.owl-item.active.center').length
      ? $(this.$element).find('.owl-item.active.center')
      : $(this.$element).find('.owl-item.active').eq(0);
    
    current.addClass('current');
  });

  yearsCarousel.owlCarousel({
    items: 11,
    loop: false,
    dots: false,
    responsiveClass: true,
    center: true,
    linked: '.milestones-carousel',
    responsive: {          
      0: {
        items: 4,
      },
      768: {          
        items: 8,  
      },
      992: {          
        items: 12,  
      },
      1200: {          
        items: 16,  
      },
      1400: {          
        items: 20,  
      },
    },
  });

})(jQuery);

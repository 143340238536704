// Gallery Modal Video
var galleryModal = document.getElementById('gallery-modal');

if (galleryModal) {
  // Stop the video if modal is hidden
  galleryModal.addEventListener('hidden.bs.modal', function (event) {
    var galleryModalVideo = document.getElementById('gallery-modal-video');
    galleryModalVideo.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
  });

  // Stop the video if not an active slide
  galleryModal.addEventListener('slide.bs.carousel', function (event) {
    var galleryModalVideo = document.getElementById('gallery-modal-video');
    galleryModalVideo.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
  });
}
